import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import Complete from "./Components/Complete";
import { useVoice } from "./../../../hook/useVoice";
import { placeholderImage } from "../../../Constants";

export default function FirstQuestion() {
  const [open, setOpen] = useState(true);
  const [inputValues, setInputValues] = useState({});
  const [firstCorrect, setFirstCorrect] = useState();
  const [secondCorrect, setSecondCorrect] = useState();
  const [thirdCorrect, setThirdCorrect] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const question = `Mike, Loreta and Hanif, engineers at a wind turbine constructor, are discussing performance and suitability issues relating to offshore wind turbines. Listen to the conversation and answer the following questions.
Mike: Obviously, a tubular steel t ow er only gives you sufficient struct Ural strength h if you give it adequate protection from corrosion - fl,e big problem with off hore installations. So, technically, you could say steel is inappropriate in t hat environment . Loret a: They make ships out of it. Mike: I know , Loret a, but only because t here's no cost-effective alternative. But we're not talking about ships, we're 
t alking about fixed structures. The point is, I think we should look more seriously at alternatives to all-st eel support s. And t he obvious alternative is reinforced concrete. Loreta: We've already looked int o it , though, and it wasn't cost-effective. Mike: Not in t he short t erm. But w e didn't really look int o it properly over the long term. Loret a: But you made t he point yourself, Mike, that st eel's completely ineffective ifit 's corroded. And one of t he main constituent s of reinforced concrete is steel. Mike: lt 's protected, though, isn't it ? lt 's embedded inside concrete. That 's a much more effective protection than paint. Loret a: Not necessarily. Lf w e're talking about t he long t erm, as you say, w hat happens t o concrete when it's exposed to the sea for a few years? lt erodes. Which means t he steel event ually gets exposed. You look at concrete coastal defences. How oft en do you see t he concrete all crumbling aw ay, and all the steel exposed
`;
  const voice = useVoice();

  const makeVoice = useCallback(
    async ({ title }) => {
      if (voice) {
        await voice(title);
      }
    },
    [voice]
  );

  useEffect(() => {
    makeVoice({ title: question });
  }, [question, makeVoice]);

  const handleFieldValueChange = (field, value) => {
    setInputValues({
      ...inputValues,
      [field]: value,
    });
  };

  const handleSubmit = () => {
    if (inputValues?.firstAnswer === "The tower") {
      setFirstCorrect(true);
    } else {
      setFirstCorrect(false);
    }

    if (
      inputValues?.secondAnswer === "Corrosion due to the presence of saltwater"
    ) {
      setSecondCorrect(true);
    } else {
      setSecondCorrect(false);
    }

    if (inputValues?.thirdAnswer === "Steel and reinforced concrete") {
      setThirdCorrect(true);
    } else {
      setThirdCorrect(false);
    }

    if (firstCorrect && secondCorrect === true) {
      handleClose();
    }
  };
  return (
    <>
      <Box>
        <img
          src={placeholderImage}
          alt=""
          style={{ margin: "0 auto", width: "100%", maxHeight: "70vh" }}
        />
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            maxHeight: "70%",
            width: "fit-content",
            maxWidth: { xs: "100%", md: "50%" },
          },
        }}
      >
        <DialogTitle sx={{ position: "relative" }}>
          <Typography variant="h3" sx={{ mb: "1em", color: "#25AAE1" }}>
            Listening
          </Typography>

          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: "5px", right: "5px" }}
          >
            <Close
              sx={{
                "&:hover": {
                  color: "red",
                  transform: "scale(1.2)",
                  transition: "transform 0.3s ease-in-out",
                },
              }}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{question}</Typography>
          <Complete
            question={`Which wind turbine component do the engineers discuss?`}
            answer={"The tower"}
            onFieldValueChange={handleFieldValueChange}
            field="firstAnswer"
            result={firstCorrect}
          />

          <Complete
            question={`What is the big problem with offshore installations?`}
            answer={"Corrosion due to the presence of saltwater"}
            onFieldValueChange={handleFieldValueChange}
            field="secondAnswer"
            result={secondCorrect}
          />

          <Complete
            question={`Which two types of construction material are being compared?`}
            answer={"Steel and reinforced concrete"}
            onFieldValueChange={handleFieldValueChange}
            field="thirdAnswer"
            result={thirdCorrect}
          />
        </DialogContent>

        <DialogActions
          sx={{ p: "0 0 2em 0", display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              bgcolor: "#fab732",
              p: "0.7em 2.5em",
              "&:hover": {
                bgcolor: "#d0a400",
              },
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
