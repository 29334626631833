import { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import SeekerBar from "./SeekerBar";
import WaveSurfer from "wavesurfer.js";
import PlayBtn from "../../../SVGs/PlayBtn";
import Overlay from "./Overlay";

function VideoPlayer({ onVideoEnd }) {
  const [currentTime, setCurrentTime] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const [volume, setVolume] = useState(1);

  const videoRef = useRef();
  const waveSurfer = useRef(null);

  useEffect(() => {
    if (!waveSurfer?.current) {
      waveSurfer.current = WaveSurfer.create({
        container: "#waveform",
        waveColor: "#b4b4b4",
        progressColor: "#25AAE1",
        height: 75,
        responsive: true,
        cursorColor: "#EA5069",
        cursorWidth: 3,
      });

      videoRef.current.addEventListener("loadedmetadata", () => {
        const duration = videoRef.current.duration;
        setCurrentTime(videoRef.current.currentTime);
        waveSurfer.current.seekTo(videoRef.current.currentTime / duration);
        waveSurfer.current.load(videoRef.current.currentSrc);
      });
      waveSurfer.current.on("audioprocess", () => {
        setCurrentTime(waveSurfer.current.getCurrentTime());
      });
      waveSurfer.current.setVolume(0);
    }
  }, []);

  useEffect(() => {
    if (!isVideoPaused) {
      waveSurfer.current.play();
    } else {
      waveSurfer.current.pause();
    }
  }, [isVideoPaused]);

  const handleVideoPlay = () => {
    setIsVideoPaused(false);
    videoRef.current.play();
    waveSurfer.current.play();
  };

  const handleVideoPause = () => {
    setIsVideoPaused(true);
    videoRef.current.pause();
    waveSurfer.current.pause();
  };

  const handleWaveformSeek = (event) => {
    const waveformWidth = event.target.clientWidth;
    const clickPosition = event.nativeEvent.offsetX;
    const percentage = clickPosition / waveformWidth;
    const seekTime = videoRef.current.duration * percentage;

    setCurrentTime(seekTime);
    videoRef.current.currentTime = seekTime;
    waveSurfer.current.seekTo(percentage);
  };

  const handleVideoSeek = (event) => {
    const seekTime = event.target.currentTime;
    setCurrentTime(seekTime);
    waveSurfer.current.seekTo(seekTime / videoRef.current.duration);
  };

  const handleWaveformDragStart = () => {
    setIsSeeking(true);
  };

  const handleWaveformDrag = (event) => {
    if (!isSeeking) return;
    handleWaveformSeek(event);
  };

  const handleWaveformDragEnd = () => {
    setIsSeeking(false);
    videoRef.current.currentTime = currentTime;
  };

  const handleVolumeChange = (event) => {
    const volume = event.target.value;
    setVolume(volume);
    videoRef.current.volume = volume;
  };

  const handleVideoEnd = () => {
    onVideoEnd();
  };

  return (
    <Box sx={{ width: 1 }}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <video
          src="./images/neoVideo.mp4"
          poster={"/images/videoBg.jpg"}
          style={{
            width: "100%",
            maxHeight: "70vh",
          }}
          onPlay={handleVideoPlay}
          onPause={handleVideoPause}
          onTimeUpdate={handleVideoSeek}
          onLoadedMetadata={() => {
            const duration = videoRef.current.duration;
            setCurrentTime(videoRef.current.currentTime);
            waveSurfer.current.seekTo(videoRef.current.currentTime / duration);
          }}
          ref={videoRef}
          onEnded={handleVideoEnd}
        />

        {isVideoPaused && (
          <>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
                fill: "#f9f9f9",
                stroke: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "30px",
                    sm: "60px",
                    md: "80px",
                    xl: "100px",
                  },
                  cursor: "pointer",
                  transition: "0.5s all",
                }}
                onClick={handleVideoPlay}
              >
                <PlayBtn />
              </Box>
            </Box>

            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
                pointerEvents: "none",
              }}
            >
              <img
                src="/images/videobgd.svg"
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
          </>
        )}

        {!isVideoPaused && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 10,
            }}
            onClick={handleVideoPause}
          ></Box>
        )}

        <Overlay volume={volume} handleVolumeChange={handleVolumeChange} />
      </Box>

      {/* <FeaturesBar /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SeekerBar
          handleWaveformSeek={handleWaveformSeek}
          handleWaveformDragStart={handleWaveformDragStart}
          handleWaveformDrag={handleWaveformDrag}
          handleWaveformDragEnd={handleWaveformDragEnd}
          currentTime={currentTime}
          videoRef={videoRef}
          isVideoPaused={isVideoPaused}
        />
      </Box>
    </Box>
  );
}

export default VideoPlayer;
