import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Close } from "@mui/icons-material";
import { placeholderImage } from "../../../../Constants";

export default function FirstQuestion() {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const questions = [
    "appropriate",
    "consistent",
    "cost-effective",
    "effective",
    "efficient",
    "sufficient/adequate",
  ];
  const answers = [
    "suitable a the right solution for a particular situation",
    "reliable b good enough for the intended function",
    "economical c performs a function well",
    "works quickly and well",
    "makes the most of resources, isn’t wasteful",
    "doesn’t break down, always performs in the same way",
  ];

  const correctAnswers = ["B", "E", "C", "A", "F", "D"];

  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(questions.length).fill("")
  );
  const availableOptions = Array.from({ length: answers.length }, (_v, i) =>
    String.fromCharCode(65 + i)
  );
  const [score, setScore] = useState(null);

  const evaluateAnswers = () => {
    let currentScore = 0;
    const updatedSelectedAnswers = selectedAnswers.map(
      (selectedAnswer, index) => {
        if (selectedAnswer === correctAnswers[index]) {
          currentScore++;
          return selectedAnswer;
        } else return "wrong";
      }
    );

    setSelectedAnswers(updatedSelectedAnswers);
    setScore(currentScore);
  };

  return (
    <>
      <Box>
        <img
          src={placeholderImage}
          style={{ margin: "0 auto", width: "100%", maxHeight: "70vh" }}
        />

        <Typography
          variant="subtitle1"
          sx={{
            bgcolor: "#F9E9E9 ",
            textAlign: "center",
            color: "#000",
            m: "2em 0 0 0",
            p: "0.5em",
          }}
        >
          Note: Speaking or others features are applicable will need it
        </Typography>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            maxHeight: "70%",
            width: "fit-content",
            maxWidth: { xs: "100%", md: "50%" },
          },
        }}
      >
        <DialogContent sx={{ position: "relative" }}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: "5px", right: "5px" }}
          >
            <Close
              sx={{
                "&:hover": {
                  color: "red",
                  transform: "scale(1.2)",
                  transition: "transform 0.3s ease-in-out",
                },
              }}
            />
          </IconButton>
          <Box sx={{ p: "2em" }}>
            <Typography variant="h4" sx={{ mb: "2em", color: "#25AAE1" }}>
              Match the words to the definitions:{" "}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "space-between",
                gap: "2em",
                marginBottom: "1em",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "start",
                  gap: "1em",
                }}
              >
                {questions?.map((question, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      gap: "1em",
                      color:
                        selectedAnswers[index] === "wrong" ? "red" : "inherit",
                    }}
                  >
                    <Typography variant="h6">{`${
                      index + 1
                    }. ${question}`}</Typography>
                    <FormControl size="small">
                      <Select
                        value={selectedAnswers[index]}
                        onChange={(e) =>
                          setSelectedAnswers(
                            selectedAnswers.map((answer, i) =>
                              i === index ? e.target.value : answer
                            )
                          )
                        }
                      >
                        <MenuItem value="">Select answer</MenuItem>
                        {availableOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "start",
                  gap: "1em",
                }}
              >
                {answers?.map((answer, index) => (
                  <Typography key={index} variant="h6">{`${String.fromCharCode(
                    65 + index
                  )}. ${answer}`}</Typography>
                ))}
              </Box>
            </Box>

            <Button
              variant="contained"
              onClick={evaluateAnswers}
              sx={{
                bgcolor: "#fab732",
                p: "0.7em 2.5em",
                "&:hover": {
                  bgcolor: "#d0a400",
                },
              }}
            >
              Check Answers
            </Button>

            {score !== null && (
              <Typography variant="h5" sx={{ mt: "1em" }}>
                Score: {score} out of {questions.length}
              </Typography>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
