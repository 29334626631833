import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { Close } from "@mui/icons-material";
import Complete from "./Components/Complete";

export default function FirstQuestion() {
  const [open, setOpen] = useState(true);
  const [inputValues, setInputValues] = useState({});
  const [firstCorrect, setFirstCorrect] = useState();
  const [secondCorrect, setSecondCorrect] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const handleFieldValueChange = (field, value) => {
    setInputValues({
      ...inputValues,
      [field]: value,
    });
  };

  const handleSubmit = () => {
    if (inputValues?.functionQuestion === "electricity") {
      setFirstCorrect(true);
    } else {
      setFirstCorrect(false);
    }

    if (
      inputValues?.advantagesQuestion ===
      "Renewable energy, non-polluting energy source"
    ) {
      setSecondCorrect(true);
    } else {
      setSecondCorrect(false);
    }

    if (firstCorrect && secondCorrect === true) {
      handleClose();
    }
  };

  return (
    <>
      <Box>
        <img
          src="./images/windTurbines.jpg"
          alt=""
          style={{ margin: "0 auto", width: "100%", maxHeight: "70vh" }}
        />
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            maxHeight: "70%",
            width: "fit-content",
            maxWidth: { xs: "100%", md: "50%" },
          },
        }}
      >
        <DialogContent sx={{ position: "relative" }}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: "5px", right: "5px" }}
          >
            <Close
              sx={{
                "&:hover": {
                  color: "red",
                  transform: "scale(1.2)",
                  transition: "transform 0.3s ease-in-out",
                },
              }}
            />
          </IconButton>
          <Complete
            question={`What function do wind turbines perform? \n wind turbines use wind to make……………….`}
            answer={"electricity"}
            onFieldValueChange={handleFieldValueChange}
            field="functionQuestion"
            result={firstCorrect}
          />

          <Complete
            question={`What are the main advantages of wind turbines?`}
            answer={"Renewable energy, non-polluting energy source"}
            onFieldValueChange={handleFieldValueChange}
            field="advantagesQuestion"
            result={secondCorrect}
          />
        </DialogContent>

        <DialogActions
          sx={{ p: "0 0 2em 0", display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              bgcolor: "#fab732",
              p: "0.7em 2.5em",
              "&:hover": {
                bgcolor: "#d0a400",
              },
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
