import VideoPlayer from "./components/VideoPlayer";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getInteractiveAction } from "../../store/actions/interactive";
import { getPostsAction } from "../../store/actions/poll";
import { Box, Button, Typography } from "@mui/material";
import ForwardIcon from "@mui/icons-material/Forward";
import SecondQuestion from "./Questions/Second";
import ThirdQuestion from "./Questions/Third";
import MatchComponent from "./Questions/Components/Match";
import CustomizedSteppers from "./components/Features";
import FirstQuestion from "./Questions/First";

function PyxizTV() {
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);

  const dispatch = useDispatch();

  const fetchPosts = useCallback(
    ({ page, next }) => {
      getPostsAction({
        page,
        limit: 6,
        pageId: 141,
        next,
        type: "polls",
        id: 2113,
      })(dispatch);
    },
    [dispatch]
  );
  const fetchData = async ({ id, demo }) => {
    await getInteractiveAction(id, demo)(dispatch);
  };
  useEffect(() => {
    fetchData({ id: 14 });
    fetchPosts({ page: 1 });
  }, []);

  const handleBack = () => {
    if (currentComponentIndex > 0) {
      setCurrentComponentIndex(currentComponentIndex - 1);
    }
  };

  const handleContinue = () => {
    if (currentComponentIndex < components.length - 1) {
      setCurrentComponentIndex(currentComponentIndex + 1);
    }
  };

  const components = [
    <VideoPlayer onVideoEnd={handleContinue} />,
    <FirstQuestion />,
    <SecondQuestion />,
    <ThirdQuestion />,
    <MatchComponent />,
  ];

  return (
    <>
      {components[currentComponentIndex]}
      <CustomizedSteppers
        activeStep={currentComponentIndex + 1}
        progress={currentComponentIndex}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "2em",
          m: "2em 0",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleBack}
          disabled={currentComponentIndex === 0}
          sx={{
            borderColor: "#fab732",
            p: "0.7em 2.5em",
            "&:hover": {
              borderColor: "#d0a400",
              "> & *": {
                color: "#d0a400",
              },
            },
          }}
        >
          <ForwardIcon
            sx={{
              transform: "rotate(180deg)",
              color: "#fab732",
            }}
          />
        </Button>
        <Button
          variant="contained"
          onClick={handleContinue}
          disabled={currentComponentIndex === components.length - 1}
          sx={{
            bgcolor: "#fab732",
            p: "0.7em 2.5em",
            "&:hover": {
              bgcolor: "#d0a400",
            },
          }}
        >
          Continue
        </Button>
      </Box>
      <Typography
        variant="subtitle1"
        sx={{
          bgcolor: "#6B6B6B",
          textAlign: "center",
          color: "#FFF",
          m: "2em 0 0 0",
          p: "0.5em",
        }}
      >
        Resource: Cambridge English for Engineering
      </Typography>
    </>
  );
}

export default PyxizTV;
