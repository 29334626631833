import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import Choices from "./Components/Choices";
import { placeholderImage } from "./../../../Constants/index";
import { useState } from "react";
import { Close } from "@mui/icons-material";

export default function FirstQuestion() {
  const [open, setOpen] = useState(true);
  const [answer, setAnswer] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAnswer = () => {
    setAnswer(true);
  };

  return (
    <>
      <Box>
        <img
          src={placeholderImage}
          style={{ margin: "0 auto", width: "100%", maxHeight: "70vh" }}
        />
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            maxHeight: "70%",
            width: "fit-content",
            maxWidth: { xs: "100%", md: "50%" },
          },
        }}
      >
        <DialogContent sx={{ position: "relative" }}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: "5px", right: "5px" }}
          >
            <Close
              sx={{
                "&:hover": {
                  color: "red",
                  transform: "scale(1.2)",
                  transition: "transform 0.3s ease-in-out",
                },
              }}
            />
          </IconButton>
          <Choices
            question={"the track train is"}
            answer={1}
            choice={[
              { title: "Paris-Strasbourg", value: 1 },
              { title: "Paris-London", value: 2 },
              { title: "Strasbourg-Lyon", value: 3 },
            ]}
            handleClose={handleClose}
            handleAnswer={handleAnswer}
          />
        </DialogContent>
        <DialogActions
          sx={{ p: "0 0 2em 0", display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              bgcolor: "#fab732",
              p: "0.7em 2.5em",
              "&:hover": {
                bgcolor: "#d0a400",
              },
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
