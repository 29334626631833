import { useState, useRef, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Live from "../../../SVGs/LiveIcon";
import Eye from "../../../SVGs/Eye";
import Competition from "../../../SVGs/Competition";
import TranslateBtn from "../../../SVGs/TranslateBtn";
import CustomerService from "../../../SVGs/CustomerService";
import SendBtn from "../../../SVGs/SendBtn";
import InsertEmoji from "../../../SVGs/InsertEmoji";
import SortIcon from "../../../SVGs/SortIcon";
import ArrowDown from "../../../SVGs/ArrowDown";
import HalfStar from "../../../SVGs/HalfStar";
import Emoji1 from "../../../SVGs/Emoji1";
import Emoji2 from "../../../SVGs/Emoji2";
import Emoji3 from "../../../SVGs/Emoji3";
import Emoji4 from "../../../SVGs/Emoji4";
import Emoji5 from "../../../SVGs/Emoji5";
import Emoji6 from "../../../SVGs/Emoji6";
import Emoji7 from "../../../SVGs/Emoji7";
import Emoji8 from "../../../SVGs/Emoji8";
import Emoji9 from "../../../SVGs/Emoji9";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";

function Overlay({ volume, handleVolumeChange }) {
  const [comment, setComment] = useState("");
  const [messages, setMessages] = useState([]);
  const [openEmojis, setOpenEmojis] = useState(false);
  const timeoutRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (comment.trim() !== "") {
      const newMessages = [...messages, comment];

      if (newMessages.length > 2) {
        newMessages.shift();
      }

      setMessages(newMessages);
      setComment("");
      setOpenEmojis(false);
    }
  };

  useEffect(() => {
    if (messages.length > 0) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setMessages((previousMessages) => previousMessages.slice(1));
      }, 3000);
    }
  }, [messages]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        padding: { xs: "1em 1em 0.5em 1em", sm: "2em 2em 0.5em 2em" },
        position: "absolute",
        gap: "1em",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "1em", md: "2em", lg: "3em", xl: "4em" },
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "1em",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "30px",
                  sm: "40px",
                  md: "50px",
                  lg: "60px",
                  xl: "80px",
                },
              }}
            >
              <Live />
            </Box>
            <Box
              sx={{
                width: {
                  xs: "14px",
                  sm: "16px",
                  md: "18px",
                  lg: "20px",
                  xl: "22px",
                },
              }}
            >
              <Eye />
            </Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "14px",
                  lg: "16px",
                  xl: "18px",
                },
                color: "white",
                pointerEvents: "none",
              }}
            >
              23k Views
            </Typography>
          </Box>
        </Box> */}

        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "end", sm: "space-between" },
          }}
        >
          <Typography
            sx={{
              color: "#ffffff",
              pointerEvents: "none",
              zIndex: 2,
              display: { xs: "none", sm: "block" },
              fontSize: {
                sm: "20px",
                md: "25px",
                lg: "30px",
                xl: "50px",
              },
            }}
          >
            TGV Record 574.8 km 03.04.2007 EuroNews
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexDirection: "column",
          gap: "0.5em",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "middle",
            gap: "1em",
            zIndex: 20,
          }}
        >
          {messages.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "middle",
                gap: "1em",
                backgroundColor: "rgba(255, 255, 255, 0.34)",
                padding: "0.5em",
                maxWidth: "40%",
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "0.5em",
                }}
              >
                <Avatar
                  src="/images/soha.png"
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#323232",
                      pointerEvents: "none",
                    }}
                  >
                    Mona Tawfic
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#6C6C6C",
                      pointerEvents: "none",
                      wordBreak: "break-word",
                    }}
                  >
                    {msg}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#323232",
                      textDecoration: "underline",
                      textTransform: "none",
                      transition: "0.5s all",
                      "&:hover": {
                        color: "#25AAE1",
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                  >
                    Reply
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  gap: "1em",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "#7A7A7A", pointerEvents: "none" }}
                >
                  at 06.43min
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      fill: "#7A7A7A",
                      transition: "0.5s all",
                      "&:hover": {
                        fill: "#25AAE1",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <HalfStar />
                  </Box>
                  <Typography
                    variant="caption"
                    sx={{ color: "#7A7A7A", pointerEvents: "none" }}
                  >
                    23
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "end" },
              alignItems: "center",
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              sx={{
                color: "#fff",
                width: "200px",
                alignItems: "center",
              }}
            >
              <VolumeDown />
              <Slider
                min={0}
                max={1}
                step={0.01}
                aria-label="Volume"
                value={volume}
                onChange={handleVolumeChange}
                sx={{ color: "#fff" }}
              />
              <VolumeUp />
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Overlay;
