import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";

const Complete = ({
  handleVideoPlay,
  handleVideoPause,
  voice,
  question,
  answer,
  onFieldValueChange,
  field,
  result,
}) => {
  const [inputText, setInputText] = useState("");

  const changeInputText = ({ target: { value } }) => {
    setInputText(value);
    return onFieldValueChange(field, value);
  };

  return (
    <Box sx={{ p: "2em" }}>
      <Box>
        <Typography variant="h6" fontWeight={700} sx={{ py: 3 }}>
          {question}
        </Typography>
      </Box>
      <Box sx={{ width: 1, pt: 1 }}>
        <TextField
          variant="standard"
          fullWidth
          onChange={changeInputText}
          value={inputText}
          multiline
          rows={4}
          sx={{
            border: "1px solid #777",
            backgroundColor: "background.secondary",
            borderRadius: "10px",
            p: 1,
          }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </Box>
      {result === false && (
        <Typography variant="subtitle2" color="red">
          Incorrect Answer!
        </Typography>
      )}
    </Box>
  );
};

export default Complete;
