import React from "react";
import "./fonts.css";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { Provider } from "react-redux";
import store from "./store";
import PyxizTV from "./pages/PyxizTV";
import Navbar from "./Layout/Navbar";
import { Box } from "@mui/material";

function App() {
  let theme = createTheme({
    typography: {
      fontFamily: "SofiaProMedium",
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Box sx={{ minHeight: "100vh" }}>
          <Navbar />
          <PyxizTV />
        </Box>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
