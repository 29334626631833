import { Box, Grid, Typography } from "@mui/material";
import { useCallback, useState } from "react";

const Choices = ({
  handleVideoPlay,
  handleVideoPause,
  activeStep,
  voice,
  question,
  choice,
  answer,
  handleAnswer,
  handleClose,
}) => {
  // const [active, setActive] = useState();
  // const handleClick = useCallback((value) => {
  //   //   if (value === randomObject?.answer) {
  //   //     setCount((e) => e + 1);
  //   //   }
  //   setActive(value);
  // }, []);

  const [correct, setCorrect] = useState(true);

  const handleClick = (value) => {
    if (value === answer) {
      handleAnswer();
      handleClose();
    } else {
      setCorrect(false);
    }
  };
  return (
    <Box sx={{ p: "2em" }}>
      <Box>
        <Typography variant="h4" fontWeight={700} sx={{ py: 3 }}>
          {question}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {choice?.map((e, i) => (
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              //   onClick={() => handleClick(e?.value)}
              onClick={() => handleClick(i + 1)}
            >
              <Box
                sx={{
                  border: "1.5px solid #777",
                  borderRadius: 3,
                  width: 1,
                  height: { xs: "45px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  userSelect: "none",
                  cursor: "pointer",
                }}
              >
                <Typography variant="body1" textAlign={"center"}>
                  {e?.title}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
        {!correct && (
          <Typography
            variant="subtitle2"
            sx={{ color: "red", width: "100%", textAlign: "center", m: "1em" }}
          >
            Incorrect Answer!
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default Choices;
