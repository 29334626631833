import {
  Avatar,
  Box,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import ArrowDown from "../SVGs/ArrowDown";
import MenuIcon from "../SVGs/MenuIcon";
import SearchIcon from "../SVGs/SearchIcon";
import NotificationBell from "../SVGs/NotificationBell";
import DrawerMenu from "@mui/icons-material/Menu";

function Navbar({ handleDrawerToggle }) {
  return (
    <Toolbar
      sx={{ bgcolor: "white", position: "sticky", top: 0, zIndex: "100" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "white",
          width: "100%",
          maxWidth: "100%",
          flexWrap: "wrap",
        }}
      >
        <img src="./images/neo-logo.png" alt="" style={{ maxHeight: "50px" }} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: "0.5em", sm: "1em" },
            flexWrap: "wrap",
          }}
        >
          <IconButton
            sx={{
              transition: "0.5s all",
              fill: "#C8C8C8",
              "&:hover": {
                fill: "#25AAE1",
                backgroundColor: "transparent",
              },
            }}
          >
            <NotificationBell />
          </IconButton>
          <Avatar
            src="/images/person.png"
            alt=""
            sx={{
              cursor: "pointer",
              width: { xs: "30px", sm: "50px" },
              height: { xs: "30px", sm: "50px" },
            }}
          />
        </Box>
      </Box>
    </Toolbar>
  );
}

export default Navbar;
